export enum InquiryProductConsidering {
  FA = 'FA',

  FS = 'FS',

  TR = 'TR',
}
export const InquiryProductConsideringLabels = {
  [InquiryProductConsidering.FA]: 'Friends Academy（練習専用）',
  [InquiryProductConsidering.FS]: 'Friends Screen（ラウンド専用）',
  [InquiryProductConsidering.TR]: 'Training Range（練習専用、オフライン）',
};
