import './inquiry-custom-update.scss';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText, UncontrolledTooltip } from 'reactstrap';
import { isNumber, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { InquiryType, InquiryTypeLabels } from 'app/shared/model/enumerations/inquiry-type.model';
import { InquiryBusinessType, InquiryBusinessTypeLabels } from 'app/shared/model/enumerations/inquiry-business-type.model';
import {
  InquiryProductConsidering,
  InquiryProductConsideringLabels,
} from 'app/shared/model/enumerations/inquiry-product-considering.model';
import { InquiryReasonContacting, InquiryReasonContactingLabels } from 'app/shared/model/enumerations/inquiry-reason-contacting.model';

import { getEntity, updateEntity, createEntity, reset } from './inquiry-custom.reducer';
import { CustomLabelWithTooltip } from './CustomLabelWithTooltip';
import PrivacyPolicy from './PrivacyPolicy';

import { toast } from 'react-toastify';

import axios from 'axios';

export const InquiryUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const inquiryStatuses = useAppSelector(state => state.inquiryStatus.entities);
  const inquiryEntity = useAppSelector(state => state.inquiry.entity);
  const loading = useAppSelector(state => state.inquiry.loading);
  const updating = useAppSelector(state => state.inquiry.updating);
  const updateSuccess = useAppSelector(state => state.inquiry.updateSuccess);

  const [resetKey, setResetKey] = useState<number>(0);

  // 同意のチェックボックスのステータス
  const [isAgreed, setIsAgreed] = useState(false);

  /**
   * チェックボックスが変更されたときに呼び出される関数
   * @param event
   */
  const handleAgreeToTermsChange = event => {
    setIsAgreed(event.target.checked);
  };

  // 更新が成功したらモーダルを閉じる
  useEffect(() => {
    if (updateSuccess) {
      toast.success('メール送信に成功しました。');
    }
  }, [updateSuccess]);

  // お問い合わせエンティティを保存する関数
  const saveEntity = values => {
    const entity = {
      ...inquiryEntity, // 既存のお問い合わせエンティティを展開
      ...values, // フォームから受け取った値を展開
    };

    // 新規作成か更新かに応じて、適切なアクションをディスパッチする
    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }

    // 成功した後にフォームをリセット
    // Reactでは、コンポーネントのkey属性が変更されると、そのコンポーネントが破棄されて再生成されるため、フォームがリセットされます。
    setResetKey(prevKey => prevKey + 1); // ここでresetKeyステートを更新する
  };

  /**
   * 送信ボタン押下時のチェック
   * @param values
   */
  const handleCustomSubmit = values => {
    // 選択しなかった場合はDEFAULTを設定する。
    values.inquiryBusinessType = values.inquiryBusinessType || 'DEFAULT';
    values.inquiryProductConsidering = values.inquiryProductConsidering || 'DEFAULT';
    values.inquiryReasonContacting = values.inquiryReasonContacting || 'DEFAULT';

    saveEntity({
      ...values,
    });
  };

  const updateZipcode = event => {
    const zipCode = document.getElementById('inquiry-zipCode') as HTMLInputElement;
    const prefecture = document.getElementById('inquiry-prefecture') as HTMLInputElement;

    const url = '/api/inquiries/zipSearch/' + zipCode.value;
    axios
      .get(url)
      .then(res => {
        if (res.data.results) {
          const result = res.data.results[0];
          prefecture.value = result['address1'] + result['address2'] + result['address3'];
          prefecture.focus();
          event.target.focus();
        } else {
          zipCode.value = '';
          prefecture.value = '';
          toast.error('郵便番号が検索できません。');

          zipCode.focus();
          prefecture.focus();
          event.target.focus();
        }
      })
      .catch(err => {
        zipCode.value = '';
        prefecture.value = '';
        toast.error('郵便番号が検索できません。');

        zipCode.focus();
        prefecture.focus();
        event.target.focus();
      });
  };

  const validateFurigana = (value: string) => {
    if (!value.match(/[^ァ-ヶー\u3000]/)) {
      return true;
    }
    return '「フリガナ」はカタカナで入力してください。';
  };

  return (
    <div className="contect-content">
      <Row className="justify-content-center">
        <Col md="12" className="text-align-center">
          <h2 className="inquiry-title" id="golfApp.inquiry.home.createOrEditLabel" data-cy="InquiryCreateUpdateHeading">
            資料請求・お問い合わせ
          </h2>
        </Col>
        <Col className="inquiry-form" md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm className="row" onSubmit={handleCustomSubmit} key={resetKey}>
              <CustomLabelWithTooltip label="お名前※" htmlFor="inquiry-contactName" id="contactNameLabel" />
              <ValidatedField
                id="inquiry-contactName"
                name="contactName"
                data-cy="contactName"
                type="text"
                className="col-md-9"
                placeholder="例：松山　一太郎"
                validate={{
                  required: { value: true, message: 'この項目は入力必須です' },
                  pattern: {
                    value: /^[^\d]*$/,
                    message: '「お名前」はカナ、漢字で入力してください。',
                  },
                }}
              />

              <CustomLabelWithTooltip label="フリガナ※" htmlFor="inquiry-contactNameKana" id="contactNameKanaLabel" />
              <ValidatedField
                id="inquiry-contactNameKana"
                name="contactNameKana"
                data-cy="contactNameKana"
                type="text"
                className="col-md-9"
                placeholder="例：マツヤマ　イチタロウ"
                validate={{
                  required: { value: true, message: 'この項目は入力必須です' },
                  validate: validateFurigana,
                }}
              />

              <CustomLabelWithTooltip label="会社名" htmlFor="inquiry-companyName" id="companyNameLabel" />
              <ValidatedField
                id="inquiry-companyName"
                name="companyName"
                data-cy="companyName"
                type="text"
                className="col-md-9"
                placeholder="会社名をご入力ください"
              />

              <CustomLabelWithTooltip label="ご住所※" htmlFor="inquiry-zipCode" id="zipCodeLabel" />
              <ValidatedField
                id="inquiry-zipCode"
                name="zipCode"
                data-cy="zipCode"
                type="text"
                className="col-md-3"
                placeholder="例：000-0000"
                validate={{
                  required: { value: true, message: 'この項目は入力必須です' },
                  maxLength: { value: 10, message: '「郵便番号」は10桁以下に入力してください。' },
                  pattern: {
                    value: /^[^\u{FF01}-\u{FF5E}]*$/u,
                    message: '「郵便番号」は半角で入力してください。',
                  },
                }}
              />
              <div className="col-md-6">
                {' '}
                <Button id="zipCode-btn" onClick={updateZipcode}>
                  <FontAwesomeIcon icon="search" />
                  住所検索
                </Button>{' '}
              </div>

              <div className="col-md-3 inquiry-label"></div>
              <ValidatedField
                id="inquiry-prefecture"
                name="prefecture"
                data-cy="prefecture"
                type="text"
                className="col-md-9"
                placeholder="例：東京都新宿区西新宿"
                validate={{
                  required: { value: true, message: 'この項目は入力必須です' },
                }}
              />

              <div className="col-md-3 inquiry-label "></div>
              <ValidatedField
                id="inquiry-address"
                name="address"
                data-cy="address"
                type="text"
                className="col-md-9"
                placeholder="例：8-14-24 建物名、部屋番号"
                validate={{
                  required: { value: true, message: 'この項目は入力必須です' },
                }}
              />

              <CustomLabelWithTooltip label="メールアドレス※" htmlFor="inquiry-emailAddress" id="emailAddressLabel" />
              <ValidatedField
                id="inquiry-emailAddress"
                name="emailAddress"
                data-cy="emailAddress"
                type="text"
                className="col-md-9"
                placeholder="例：tanaka@miraku.io"
                validate={{
                  required: { value: true, message: 'この項目は入力必須です' },
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: '正しい形式のメールアドレスではありません。',
                  },
                }}
              />

              <CustomLabelWithTooltip label="電話番号※" htmlFor="inquiry-phoneNumber" id="phoneNumberLabel" />
              <ValidatedField
                id="inquiry-phoneNumber"
                name="phoneNumber"
                data-cy="phoneNumber"
                type="text"
                className="col-md-9"
                placeholder="例：000-000-0000"
                validate={{
                  required: { value: true, message: 'この項目は入力必須です' },
                  maxLength: { value: 20, message: '「電話番号」は20桁以下の半角文字で入力してください。' },
                  pattern: {
                    value: /^[^\u{FF01}-\u{FF5E}]*$/u,
                    message: '「電話番号」は20桁以下の半角文字で入力してください。',
                  },
                }}
              />

              <CustomLabelWithTooltip label="お問い合わせ項目※" htmlFor="inquiry-inquiryType" id="inquiryTypeLabel" />
              <ValidatedField
                id="inquiry-inquiryType"
                name="inquiryType"
                data-cy="inquiryType"
                className="col-md-9"
                type="select"
                validate={{
                  required: { value: true, message: 'この項目は入力必須です' },
                }}
              >
                <option value="">選択してください</option> {/* 未選択の場合の表示 */}
                {Object.keys(InquiryType).map(inquiryType => (
                  <option value={inquiryType} key={inquiryType}>
                    {InquiryTypeLabels[inquiryType]} {/* ENUMの値を日本語に変換する */}
                  </option>
                ))}
              </ValidatedField>

              <CustomLabelWithTooltip label="予定業態" htmlFor="inquiry-inquiryBusinessType" id="inquiryBusinessTypeLabel" />
              <ValidatedField
                id="inquiry-inquiryBusinessType"
                name="inquiryBusinessType"
                data-cy="inquiryBusinessType"
                className="col-md-9"
                type="select"
              >
                <option value="">選択してください</option> {/* 未選択の場合の表示 */}
                {Object.keys(InquiryBusinessType).map(inquiryBusinessType => (
                  <option value={inquiryBusinessType} key={inquiryBusinessType}>
                    {InquiryBusinessTypeLabels[inquiryBusinessType]} {/* ENUMの値を日本語に変換する */}
                  </option>
                ))}
              </ValidatedField>

              <CustomLabelWithTooltip
                label="ご検討の製品"
                htmlFor="inquiry-inquiryProductConsidering"
                id="inquiryProductConsideringLabel"
              />
              <ValidatedField
                id="inquiry-inquiryProductConsidering"
                name="inquiryProductConsidering"
                data-cy="inquiryProductConsidering"
                className="col-md-9"
                type="select"
              >
                <option value="">選択してください</option> {/* 未選択の場合の表示 */}
                {Object.keys(InquiryProductConsidering).map(inquiryProductConsidering => (
                  <option value={inquiryProductConsidering} key={inquiryProductConsidering}>
                    {InquiryProductConsideringLabels[inquiryProductConsidering]} {/* ENUMの値を日本語に変換する */}
                  </option>
                ))}
              </ValidatedField>

              <CustomLabelWithTooltip
                label={'お問い合わせ \n 頂いたきっかけ'}
                htmlFor="inquiry-inquiryReasonContacting"
                id="inquiryReasonContactingLabel"
              />
              <ValidatedField
                id="inquiry-inquiryReasonContacting"
                name="inquiryReasonContacting"
                data-cy="inquiryReasonContacting"
                className="col-md-9"
                type="select"
              >
                <option value="">選択してください</option> {/* 未選択の場合の表示 */}
                {Object.keys(InquiryReasonContacting).map(inquiryReasonContacting => (
                  <option value={inquiryReasonContacting} key={inquiryReasonContacting}>
                    {InquiryReasonContactingLabels[inquiryReasonContacting]} {/* ENUMの値を日本語に変換する */}
                  </option>
                ))}
              </ValidatedField>

              <CustomLabelWithTooltip label="その他お問い合わせ内容" htmlFor="inquiry-inquiryContent" id="inquiryContentLabel" />
              <ValidatedField
                id="inquiry-inquiryContent"
                name="inquiryContent"
                data-cy="inquiryContent"
                className="col-md-9"
                type="textarea"
                rows={5} // サイズを調整する場合はrowsプロパティを指定する
                validate={{
                  minLength: { value: 10, message: '10文字以上の内容で入力してください。' },
                }}
              />

              <Row>
                <Col className="text-align-center">
                  <span className="form-label">個人情報保護方針</span>
                </Col>
              </Row>
              <Row id="md_12">
                <Col md="12">
                  <PrivacyPolicy />
                </Col>
              </Row>
              <Row>
                <Col md="12" className="text-align-center">
                  <label className="label-checkbox">
                    <ValidatedField
                      id="inquiry-agreeToTerms"
                      name="agreeToTerms"
                      data-cy="agreeToTerms"
                      check
                      type="checkbox"
                      onChange={handleAgreeToTermsChange}
                    />
                    同意します<span className="required-asterisk">※</span>
                  </label>
                </Col>
              </Row>
              <Row>
                <Col md="12" className="text-align-center">
                  <Button
                    id="save-entity"
                    data-cy="entityCreateSaveButton"
                    type="submit"
                    disabled={updating || !isAgreed}
                    color="btn btn-primary"
                    className="btn-double-size inquiry-btn-color"
                  >
                    送信
                  </Button>
                </Col>
              </Row>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default InquiryUpdate;
