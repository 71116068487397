/* eslint-disable no-irregular-whitespace */
import './footer.scss';

import React from 'react';

import { Col, Row } from 'reactstrap';
import GolfHeader from 'app/components/header/GolfHeader';

const Footer = () => {
  if (location.pathname.startsWith('/golf')) {
    // ゴルフ紹介のフッターはroutes.tsxで定義している
    return;
  } else {
    return (
      <div className="footer footer-miraku" id="footer">
        <Row>
          <Col md="6">
            <Row className="footer-content">
              <Col className="footer-underbar"></Col>
              <Col className="footer-title font_6">
                <span>CONTACT</span>
              </Col>
              <Col>
                <p className="font_8">
                  <span>〒160-0023</span>
                </p>
                <p className="font_8">
                  <span>東京都新宿区西新宿8-14-24 西新宿KFビル708号</span>
                </p>
                <p className="font_8">
                  <span>Tel: 03-6826-9746 &nbsp;&nbsp; Fax: 050-6868-2567</span>
                </p>
                <p className="font_8">
                  <span>
                    <a href="mailto:info@mysite.com" target="_self">
                      info@miraku.io
                    </a>
                  </span>
                </p>
                <div id="comp-irvzpphf" className="KcpHeO tz5f0K comp-irvzpphf wixui-rich-text" data-testid="richText Element">
                  <p className="font_9">
                    <span>
                      © 2023&nbsp;<span className="underline">Miraku Inc.</span>
                    </span>
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
};

export default Footer;
