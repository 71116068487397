/* eslint-disable no-irregular-whitespace */
import React from 'react';

const PrivacyPolicy = () => {
  const backgroundColor = '#f2f2f2'; // 背景色

  return (
    <textarea
      style={{
        width: '100%',
        height: '400px',
        backgroundColor: '#ffffff',
        padding: '10px',
        border: '1px',
        borderStyle: 'solid',
        borderRadius: '5px',
        fontSize: '14px',
        fontFamily: 'Arial, sans-serif',
        color: '#333',
      }}
      readOnly={true}
      value={`
プライバシーポリシー（個人情報保護方針）

株式会社Miraku（以下、「当社」という。）は，ユーザーの個人情報について以下のとおりプライバシーポリシー（以下、「本ポリシー」という。）を定めます。本ポリシーは、当社がどのような個人情報を取得し、どのように利用・共有するか、ユーザーがどのようにご自身の個人情報を管理できるかをご説明するものです。

【１．事業者情報】
法人名：株式会社Miraku
住所：東京都新宿区西新宿８－１４－２４新宿KFビル７０８号室
代表取締役：田 昌錫

【２．個人情報の取得方法】
当社はユーザーが利用登録をするとき、氏名・生年月日・住所・電話番号・メールアドレスなど個人を特定できる情報を取得させていただきます。
お問い合わせフォームやコメントの送信時には、氏名・電話番号・メールアドレスを取得させていただきます。

【３．個人情報の利用目的】
1.お申し込みいただいた資料・その他サービスの申し込み確認や各種印刷物をお届けするため。
2.お申し込みいただいたサービスのご請求、お支払いとその確認をするため。
3.メール配信サービスのお申し込みの確認やメール配信のため。展示会やその他サービスの他、何らかの理由で連絡する必要が生じたとき。
4.購買履歴等の情報の分析、ユーザー別に適した商品・サービスをお知らせするため。
5.機器設置工事のため業務委託業者など第三者へ提供します。

【４．個人データを安全に管理するための措置】
当社は個人情報を正確かつ最新の内容に保つよう努め、不正なアクセス・改ざん・漏えい滅失及び毀損を防止するため、必要なセキュリティ対策を行い、厳正な管理下で安全に取り扱います。個人情報守秘義務契約を結んだ企業に、限定された特定の業務（データ入力・印刷・梱包・配送・代金の請求・その他サービスの提供に必要な業務）の範囲で、個人情報の取り扱いを委託する場合があります。そのいずれの場合でも、当該業務の委託に必要となる最小限の個人情報のみとし、当プライバシーポリシーに則り、保護管理されます。
また、全従業員及び役員に対して教育研修を実施しています。個人情報保護規程を設け、現場での管理についても定期的に点検を行っています。

【５．個人データの共同利用】
当社は、以下のとおり共同利用を行います。

個人データの管理に関する責任者
株式会社Miraku
共同して利用する者の利用目的
上記「利用目的」の内容と同様。
利用項目
氏名、住所、電話番号、メールアドレス
共同して利用する者の範囲
当社企業グループを構成する企業

【６．個人データの第三者提供について】
当社は法令及びガイドラインに別段の定めがある場合を除き、同意を得ないで第三者に個人情報を提供することは致しません。

【７．保有個人データの開示、訂正】
当社は本人から個人情報の開示を求められたときには、遅滞なく本人に対しこれを開示します。個人情報の利用目的の通知や訂正、追加、削除、利用の停止、第三者への提供の停止を希望される方は以下の手順でご請求ください。
（各社請求方法を指定）
送付先住所
〒１６０－００２３
東京都新宿区西新宿８－１４－２４新宿KFビル７０８号室
株式会社Miraku　お問い合わせ窓口 info@miraku.io

【８．個人情報取り扱いに関する相談や苦情の連絡先】
当社の個人情報の取り扱いに関するご質問やご不明点、苦情、その他のお問い合わせはお問い合わせフォームよりご連絡ください。

【９．SSL（Secure Socket Layer）について】
当社のWebサイトはSSLに対応しており、WebブラウザとWebサーバーとの通信を暗号化しています。ユーザーが入力する氏名や住所、電話番号などの個人情報は自動的に暗号化されます。

【１０．cookieについて】
cookieとは、WebサーバーからWebブラウザに送信されるデータのことです。Webサーバーがcookieを参照することでユーザーのパソコンを識別でき、効率的に当社Webサイトを利用することができます。当社Webサイトがcookieとして送るファイルは、個人を特定するような情報は含んでおりません。
お使いのWebブラウザの設定により、cookieを無効にすることも可能です。

【１１．プライバシーポリシーの制定日及び改定日】
制定：2023年 04月 01日

【１２．免責事項】
当社Webサイトに掲載されている情報の正確性には万全を期していますが、利用者が当社Webサイトの情報を用いて行う一切の行為に関して、一切の責任を負わないものとします。
当社は、利用者が当社Webサイトを利用したことにより生じた利用者の損害及び利用者が第三者に与えた損害に関して、一切の責任を負わないものとします。

【１３．著作権・肖像権】
当社Webサイト内の文章や画像、すべてのコンテンツは著作権・肖像権等により保護されています。無断での使用や転用は禁止されています。

【１４．リンク】
当社Webサイトへのリンクは、自由に設置していただいて構いません。ただし、Webサイトの内容等によってはリンクの設置をお断りすることがあります。
              `}
    />
  );
};

export default PrivacyPolicy;
